import * as React from "react";
import {RouteComponentProps} from "react-router";
import {BaseStationBoard, StationBoardState} from "../components/stationBoard";

export class StationBoard extends BaseStationBoard {

    constructor(props: RouteComponentProps<{}>, context: any) {
        super(props, context);
    }

    private static sendExternalEvent(active: boolean) {
        window.parent.postMessage({
            name: "bryx-message",
            active: active,
        }, "*");
    }

    onInitialized(): void {
        StationBoard.sendExternalEvent(this.state.displayJob != null);
    }

    componentDidUpdate(prevProps: Readonly<RouteComponentProps<{}>>, prevState: Readonly<StationBoardState>, prevContext: any): void {
        const prevActive = prevState.displayJob != null;
        const currentActive = this.state.displayJob != null;
        if (prevActive != currentActive) {
            StationBoard.sendExternalEvent(currentActive);
        }
    }
}
